import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import SuccessStories from "components/success/SuccessStories";
import Layout from "components/shared/Layout";
import CTA from "components/shared/CTA";
import icon from "images/svg_icons/badge.svg";
import { graphql } from "gatsby";
import LargeTitle from "components/services/LargeTitle";

export default ({ data }) => (
  <>
    <Meta url="/success/" />
    <Nav />
    <Layout>
      <LargeTitle
        size="small"
        img={icon}
        green="Our Success"
        black="Stories"
      />
      <SuccessStories stories={data.allWpPost.edges} />
    </Layout>
    <CTA />
    <Footer />
  </>
);
export const pageQuery = graphql`
  query {
    allWpPost(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: "success" } } } }
      }
    ) {
      edges {
        node {
          featuredImage {
            node {
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          custom_data {
            metaDescription
            previewDescription
            previewTitle
          }
          slug
          title
        }
      }
    }
  }
`;
