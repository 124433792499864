import React from "react";
import * as classes from "./index.module.less";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Button from "../../shared/Button";

let Item = ({ node }) => {
  const image = getImage(node.featuredImage.node.localFile);
  return (
    <div className={classes.item}>
      <Link to={`/success/${node.slug}/`}>
        <GatsbyImage
          image={image}
          alt={node.custom_data.previewTitle}
          loading="lazy"
          width={400}
          height={200}
        />
      </Link>
      <span>
        <h4>{node.custom_data.previewTitle}</h4>
        <p>{node.custom_data.previewDescription}</p>
        <Link to={`/success/${node.slug}/`}>
          <Button ghost>Learn More</Button>
        </Link>
      </span>
    </div>
  );
};

export default ({ stories }) => (
  <div className={classes.container}>
    <div className={classes.content}>
      <div className={classes.gray}></div>
      {stories.map((it, index) => (
        <Item key={index} {...it} />
      ))}
    </div>
  </div>
);
